// src/consoleOverride.js

// Sauvegarder l'implémentation originale de console.log
const originalConsoleLog = console.log;

// Redéfinir console.log
console.log = function (...args) {
    return false;
    // Ajouter un comportement personnalisé
    originalConsoleLog("Custom Log:", ...args);

    // Exemple : Enregistrer les logs dans le stockage local ou une API
    // localStorage.setItem('appLogs', JSON.stringify(args)); 
};

// Exporter une fonction si besoin, mais ce fichier agit directement globalement
export {};
