import { Helmet } from "react-helmet";
import { Text, Heading } from "../../components";
import AdminGestionPageDistributeurNavbar from "../../components/AdminGestionPageDistributeurNavbar";
import FilmCardsquence from "../../components/FilmCardsquence";
import React, { Suspense, useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getSequence } from "services/sequencesServices";
import { getSequencesByFilm } from "services/filmsServices";
import 'video-react/dist/video-react.css';
import Footer from "components/Footer";
import ReglementChallenge from "./reglement";
import Clip from "./Clip";

export default function EditermonextraitPage() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [videosByFilm, setVideosByFilm] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const handleUrlChange = async() => {
      setVideo(null);
      fetchVideo(id);
    };
 
    handleUrlChange(); 
    // Fais défiler vers le haut
    window.scrollTo(0, 0);
  }, [location, id]);

  const fetchVideosByFilm = async (id) => {
    try {
      const videoData = await getSequencesByFilm(id);
      setVideosByFilm(videoData.sequences);
    } catch (error) {
      console.error("Error fetching videos1:", error);
      return <Navigate to="/404" />;
    }
  };
  

  const convertToSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[\s\W-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };
  const changeURL = (producteur,pseudo,title,id) => {
    window.history.pushState({}, "", "/"+producteur+"/"+pseudo+"/"+title+"/"+id);
  };


  const fetchVideo = async (id, getsequence = true) => {
    
    try {
      const videoData = await getSequence(id);
      setVideo(videoData.sequence);
      if (getsequence) fetchVideosByFilm(videoData.sequence.filmId);
      setLoadingVideo(false);
      changeURL(
        videoData.sequence.producteur!=""?videoData.sequence.producteur:videoData.sequence.film.producteur,
        videoData.sequence.film.pseudo,
        convertToSlug(videoData.sequence.title),
        videoData.sequence.id
      );
    } catch (error) {
      setLoadingVideo(true);
      console.error("Error fetching videos2:", error);
      
    }
  };
  
  if(loadingVideo && !video) return <Navigate to="/404" />;
  
  return (
    <>
      <Helmet>
        <title>ReCut - {video && video.title ? video.title : ""}</title>
        <meta name="description" content={video && video.desc ? video.desc : ""} />
        <meta property="og:title" content={`ReCut - ${video && video.title ? video.title : "ReCut"}`} />
        <meta property="og:description" content={video && video.desc ? video.desc : ""} />
        <meta property="og:image" content={video && video.id ? `${process.env.REACT_APP_URL}public/sequences/${video.id}/${video.id}.jpg` : `${process.env.REACT_APP_URL}images/logo-recut.png`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`ReCut - ${video && video.title ? video.title : "ReCut"}`} />
      </Helmet>
     
      <div className="w-full">
        <div className="flex flex-col items-center gap-2.5">
          <div className="relative self-stretch">
            <div className="flex w-full flex-col items-center">
              <div className="flex items-center self-stretch md:flex-col">
                <div className="relative w-[100%] md:h-auto md:w-full">
                  <div className="w-full">
                    <div className="bg-cover bg-no-repeat pb-[55px] md:pb-5">
                      <div className="flex flex-col">
                        <AdminGestionPageDistributeurNavbar text="CHALLENGE" challenge={"/"+video?.film.producteur+"/"+video?.film.pseudo}/>
                      </div>
                    </div>
                    <div className="relative mt-[-37px] flex flex-col items-center bg-no-repeat md:h-auto">
                      <div className="w-[100%] md:w-full">

                        {video && <Clip video={video} className="container-xs p-0 grid grid-cols-12 gap-0"/>}

                        <div className="container-xs  md:p-5 p-[50px]">

                          {/* {dataduree && <>
                          <Text className="text-[#fff]">timeStart:{dataTimeClipVideo.timeStart}  -   timeEnd:{dataTimeClipVideo.timeEnd}</Text>
                          <table className="table-auto" border="1">
                            <tr>
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}></td>
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text>Total</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text>Par Sequence</Text></td>
                            </tr>
                            <tr> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">Duree</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.dureeSequences}</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.dureeSequence}</Text></td>
                            </tr>
                            <tr> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">Max clip</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.maxclip}</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.maxclipsequence}</Text></td>
                            </tr>
                            <tr> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">Rest clip</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.maxclip-dataduree.dureeSequences}</Text></td> 
                              <td style={{border:"solid 1px #fff",textAlign:"center",width:"100px"}}><Text className="text-[#fff]">{dataduree.maxclipsequence-dataduree.dureeSequence}</Text></td>
                            </tr>
                          </table>
                          </>} */}
                          <div className="flex flex-col items-start">
                            <Heading size="headline_3" as="h2" className="w-[100%] text-center mb-[30px]">
                              RÈGLEMENT DU CHALLENGE
                            </Heading>
                            {video && <ReglementChallenge  idsequence={video.id} idfilm={video.filmId} />}
                            <Heading size="headline_3" as="h2" className="w-[100%] text-center mb-[30px]">
                              EXTRAITS ISSUS DU MÊME CHALLENGE
                            </Heading>
                            <div className="grid w-[100%] grid-cols-4 md:grid-cols-2 gap-4 sm:grid-cols-1 sm:gap-12">
                              <Suspense fallback={<div>Loading feed...</div>}>
                                {videosByFilm?.map((sequence, index) => (
                                  video?.id !== sequence.id && (
                                    <FilmCardsquence 
                                    key={"listtime" + index} 
                                    scneomondain={sequence.title} 
                                    filmId={sequence.filmId} 
                                    leschoristes={sequence.subtitle} 
                                    urlimage={sequence.urlimage} 
                                    timesequence={sequence.timesequence}
                                    idsequence={sequence.id}
                                    producteur={sequence.producteur=="" || sequence.producteur ==null?sequence.film.producteur:sequence.producteur}
                                    pseudofilm={sequence.film.pseudo}
                                    className=" md:gap-[13px]" 
                                  />
                                  )
                                ))}
                              </Suspense>
                            </div>
                            
                            <Heading size="headline_3" as="h2" className="w-[100%] text-center mt-[50px]">
                              À PROPOS DE L’EXTRAIT
                            </Heading>
                            <div className="mb-[50px] mt-[30px] w-[100%] text-center">
                              <Heading size="headline_6" as="p" className="mb-5 w-[100%] text-center">
                                {video?.desc}
                              </Heading>
                              <Heading size="subtitle_2" as="p" className="italic w-[100%] text-center">
                                {video?.desc_en}
                              </Heading>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
