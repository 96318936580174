import React, { useEffect, useRef, useState } from "react";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
export default function FilmClipFrame({
  idsequence = "0",
  timesequence = "00:00",
  setDataTimeClipVideo,
  dataTimeClipVideo = {timeStart:-1,timeEnd:-1} ,
  setDisabledClipVideo,
  filmColor = "#fff",
  maxtimeclip,
  dataduree = null,
  ...props
}) {
  const appUrl = process.env.REACT_APP_URL;
  const [range, setRange] = useState([dataTimeClipVideo.timeStart,dataTimeClipVideo.timeEnd]);
  const [thumbnails, setThumbnails] = useState([]);
  const nbClipFrame = 20; 
  const divFilmClipFrameRef = useRef(null);
  const [widthClipFrame, setWidthClipFrame] = useState(0);
  const [statsdure, setStatsdure] = useState(true);


  useEffect(() => {
    // setTimeout(() => {

    //   if(dataTimeClipVideo.timeStart == dataTimeClipVideo.timeEnd && (dataTimeClipVideo.timeEnd==0 ||dataTimeClipVideo.timeEnd==-1) && maxtimeclip>=0){
    //     setRange([0,convertTimeToSeconds(timesequence)]);
    //     setDataTimeClipVideo({timeStart:0,timeEnd:convertTimeToSeconds(timesequence)})
    //   }
    // }, 2000);
   
  }, []);
  useEffect(() => {
    setRange([dataTimeClipVideo.timeStart,dataTimeClipVideo.timeEnd]);
    if(dataTimeClipVideo.timeEnd-dataTimeClipVideo.timeStart >maxtimeclip) setStatsdure(false); else setStatsdure(true);
    generateThumbnails();
    if (divFilmClipFrameRef.current) {
        // Obtenez la largeur du div en pixels
        setWidthClipFrame(divFilmClipFrameRef.current.offsetWidth/nbClipFrame);
    }
    const handleResize = () => {
        if (divFilmClipFrameRef.current) {
            // Obtenez la largeur du div en pixels
            setWidthClipFrame(divFilmClipFrameRef.current.offsetWidth/nbClipFrame);
        }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dataduree,dataTimeClipVideo,maxtimeclip]);

  const generateThumbnails = () => {
    const thumbnails = [];
    for (let i = 1; i <= nbClipFrame; i++) {
      thumbnails.push(appUrl+"public/frames/sequence_"+idsequence+"/frame-"+i+".png");
    }
    setThumbnails(thumbnails);
  };

  function convertTimeToSeconds(timeString) {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
  }

  const handleRangeChange = (values) => {
    setRange(values);
    setDataTimeClipVideo({timeStart:values[0],timeEnd:values[1]});
  };

  
  const handleRangeChangeedEnd = (values) => {
    setDisabledClipVideo(false);
  };

  const handleRangeChangeedStart = (values) => {
    setDisabledClipVideo(true);
  };

  return (
    <>


     <div {...props} className={`${props.className} flex flex-col gap-[13px]`}>
      <div className="self-stretch container-blockFilmClipsquence container-101">
        <div className="blockFilmClipsquence mt-[-85px] sm:mt-[-63px] p-[30px] sm:p-[0px]">
          <div className="blockFilmClipFrame" style={{borderColor:filmColor}}>
            <div style={{ display: 'flex' }} className="friseClip" ref={divFilmClipFrameRef}>
              {thumbnails.map((thumbnail, index) => (
                <img key={index} src={thumbnail} alt={`frame-${index}`} style={{ 
                    width: `${widthClipFrame}px`, 
                    height: '70px', 
                    marginRight: '0px',
                    objectFit:'cover',
                    objectPosition:'left'
                }} />
              ))}
            </div>
            <RangeSlider
              min={0}
              max={convertTimeToSeconds(timesequence)}
              value={range}
              onInput={handleRangeChange}
              onThumbDragEnd={handleRangeChangeedEnd}
              onThumbDragStart={handleRangeChangeedStart}
              onRangeDragEnd={handleRangeChangeedEnd}
              onRangeDragStart={handleRangeChangeedStart}
              className={statsdure?"validdure":"invaliddure"}
            />
          </div>
          <div className="flex justify-between timeStartEndFilmClipFrame">
            <span>00:00</span>
            <span>{timesequence}</span>
          </div>
        </div>
      </div>
    </div>
    
    </>
   
  );
}