import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { BigPlayButton, ControlBar, CurrentTimeDisplay, ForwardControl, Player, PlayToggle, ProgressControl, VolumeMenuButton } from 'video-react';
import "video-react/dist/video-react.css";

const HLSSource = ({ 
  src, 
  dataTimeClipVideo={timeStart:-1,timeEnd:-1}, 
  disabledClipVideo,
  currentQualityVideo,
  setCurrentQualityVideo,
  control=true, 
  ...props }) => {
  const calculateVideoHeight = (width) => {
    return width > 1300 ? 600 : width * 9 / 16;
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 4);
  const [videoHeight, setVideoHeight] = useState(calculateVideoHeight(window.innerWidth));
  const [currentSource, setCurrentSource] = useState(src);
  const [currentTime, setCurrentTime] = useState(0);
  const [oldTimeEnd, setOldTimeEnd] = useState(0);
  
  useEffect(() => {
    let hlsurl = src.replace("720p", currentQualityVideo);
    setCurrentSource(hlsurl);
  }, [currentQualityVideo]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth - 4;
      setWindowWidth(newWidth);
      setVideoHeight(calculateVideoHeight(newWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const { timeStart } = dataTimeClipVideo;

    if (Hls.isSupported()) {
        setCurrentTime(videoRef.current.getState().player.currentTime);
        const hls = new Hls();
        hls.loadSource(currentSource);
        hls.attachMedia(videoRef.current.video.video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (!disabledClipVideo) {
                const startTime = timeStart !== -1 ? timeStart : currentTime;
                videoRef.current.video.video.currentTime = startTime;
                //videoRef.current.video.video.muted = true; // S'assurer que la vidéo est muette pour autoplay
                videoRef.current.video.video
                    .play()
                    .catch(error => {
                        console.log('Play request interrupted:', error);
                    });
            } 
            else {
              console.log('--------------------video pause---------------');
                videoRef.current.video.video.pause();
            }
        });
    } else if (videoRef.current.video.video.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.video.video.src = currentSource;
        //videoRef.current.video.video.muted = true; // S'assurer que la vidéo est muette pour autoplay
        videoRef.current.video.video
            .play()
            .catch(error => {
                console.log('Play request interrupted:', error);
            });
    }
    return () => {};
  }, [currentSource, dataTimeClipVideo, disabledClipVideo, canvasRef]);

  useEffect(() => {
    console.log("START*************canvas******************canvas******************");
    
    const { timeStart, timeEnd } = dataTimeClipVideo;
    // Vérifiez que videoRef.current et canvasRef.current sont bien définis
    if (videoRef.current && canvasRef.current) {  // Assurez-vous que video est bien l'élément vidéo HTML
        if (disabledClipVideo) {
          if (oldTimeEnd !== timeEnd) {
            captureImageAtTime(timeEnd);
            setOldTimeEnd(timeEnd); // Mettez à jour oldTimeEnd ici
          } else {
            captureImageAtTime(timeStart);
          }
        } else {
          setOldTimeEnd(timeEnd);
        }
    } else {
      console.warn("Les références videoRef ou canvasRef ne sont pas définies.");
    }
    console.log("FIN*************canvas******************canvas******************");
  }, [dataTimeClipVideo, disabledClipVideo ]);
  
  const handleTimeUpdate = () => {
    const { timeStart, timeEnd } = dataTimeClipVideo;
    if (videoRef.current) {
      if (timeEnd==timeStart && timeStart==0) {
        videoRef.current.video.video.pause();
        return false;
      }
      try {
        const currentTime = videoRef.current.getState().player.currentTime;
        setCurrentTime(currentTime);
        if (!isNaN(timeEnd) && timeEnd !== -1 && currentTime >= timeEnd) {
          videoRef.current.video.video.currentTime = timeStart;
        }
        
      } catch (error) {
        console.log('Error updating current time:', error);
      }
    }
  };

  const captureImageAtTime = (timecapture) => {
    const player = videoRef.current;
    const canvas = canvasRef.current;

    if (player && canvas) {
      const video = player.video.video; // Accès à l'élément vidéo natif

      // Aller à la 10e seconde et capturer
      video.currentTime = timecapture; 
      
      player.video.video.pause();
      video.onseeked = () => {
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // Dessiner l'image
      };
    }
  };


  return (
    <div style={{ "position" : "relative" }}>
      <Player
        ref={videoRef}
        fluid={false}
        muted={false} // Mettre la vidéo en sourdine
        height={videoHeight}
        autoPlay
        onTimeUpdate={handleTimeUpdate}
        width="100%"
        poster="/assets/poster.png"
        playsInline
        webkit-playsinline="true"
        {...props}>
        <source src={currentSource} />
        <BigPlayButton position="center" className="PlayerBigPlayButton" />
        <ControlBar autoHide={false} className='blockControlBar' disableCompletely={false}>
          <PlayToggle />
          <ProgressControl/>
        </ControlBar>
      </Player>
      <canvas
        ref={canvasRef}
        className="canvasPlayerdisabledClipVideo"
        style={{
          display: disabledClipVideo ? 'block' : 'none',
          height: `${parseInt(videoHeight, 10)}px`,
          // border: "3px solid rgb(255, 255, 255)",
        }}
      />
    </div>
  );
};

export default HLSSource;
