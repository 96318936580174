import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { BigPlayButton, ControlBar, CurrentTimeDisplay, ForwardControl, Player, PlayToggle, ProgressControl, VolumeMenuButton } from 'video-react';
import "video-react/dist/video-react.css";

const HLSSource = ({ 
  src, 
  dataTimeClipVideo, 
  disabledClipVideo,
  currentQualityVideo,
  setCurrentQualityVideo,
  control=true, 
  ...props }) => {
  const calculateVideoHeight = (width) => {
    //return width > 1300 ? 800 : width * 9 / 16;
    return width > 1300 ? 600 : width * 9 / 16;
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 4);
  const [videoHeight, setVideoHeight] = useState(calculateVideoHeight(window.innerWidth));
  const [currentSource, setCurrentSource] = useState(src);
  const [currentTime, setCurrentTime] = useState(0);
  const [oldTimeEnd, setOldTimeEnd] = useState(0);
  
  
  useEffect(() => {
    console.log("START*************canvas******************canvas******************");
    
    const { timeStart, timeEnd } = dataTimeClipVideo;
    // Vérifiez que videoRef.current et canvasRef.current sont bien définis
    if (videoRef.current && canvasRef.current) {  // Assurez-vous que video est bien l'élément vidéo HTML
        if (disabledClipVideo) {
          if (oldTimeEnd !== timeEnd) {
            captureImageAtTime(timeEnd);
            setOldTimeEnd(timeEnd); // Mettez à jour oldTimeEnd ici
          } else {
            captureImageAtTime(timeStart);
          }
        } else {
          setOldTimeEnd(timeEnd);
        }
    } else {
      console.warn("Les références videoRef ou canvasRef ne sont pas définies.");
    }
    console.log("FIN*************canvas******************canvas******************");
  }, [dataTimeClipVideo, disabledClipVideo ]);
  
  useEffect(() => {
    let hlsurl = src.replace("720p", currentQualityVideo);
    setCurrentSource(hlsurl);
  }, [currentQualityVideo]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth - 4;
      setWindowWidth(newWidth);
      setVideoHeight(calculateVideoHeight(newWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const { timeStart, timeEnd } = dataTimeClipVideo;
    
    if (Hls.isSupported()) {
      setCurrentTime(videoRef.current.getState().player.currentTime);
      const hls = new Hls();
      hls.loadSource(currentSource);
      hls.attachMedia(videoRef.current.video.video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const startTime = timeStart !== -1 ? timeStart : currentTime;
        videoRef.current.video.video.currentTime = startTime;
        if (!disabledClipVideo) {
          videoRef.current.video.video.play().catch(error => {
            console.log('Play request interrupted:', error);
          });
        }
      });
    } else if (videoRef.current.video.video.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.video.video.src = currentSource;
      videoRef.current.video.video.addEventListener('loadedmetadata', () => {
        if (!disabledClipVideo) {
          videoRef.current.video.video.play().catch(error => {
            console.log('Play request interrupted:', error);
          });
        }
      });
    }

    // const intervalId = setInterval(() => {
    //   if (videoRef.current) {
    //     const canvas = canvasRef.current;
    //     if (disabledClipVideo) {
    //         if(oldTimeEnd != timeEnd){
    //           videoRef.current.video.video.currentTime = timeEnd;
    //           videoRef.current.video.video.pause();
    //           const context = canvas.getContext('2d');
    //           context.drawImage(videoRef.current.video.video, 0, 0, canvas.width, canvas.height);
    //         }else{
    //           videoRef.current.video.video.pause();
    //           const context = canvas.getContext('2d');
    //           context.drawImage(videoRef.current.video.video, 0, 0, canvas.width, canvas.height);
    //         }
    //     }else{
    //       setOldTimeEnd(timeEnd);
    //     }
    //   }
    // },1);

    return () => {
      // clearInterval(intervalId);
    };
  }, [currentSource, dataTimeClipVideo, disabledClipVideo,canvasRef]);

  const captureImageAtTime = (timecapture) => {
    console.log("captureImageAtTime:",timecapture);
    const player = videoRef.current;
    const canvas = canvasRef.current;

    if (player && canvas) {
      const video = player.video.video; // Accès à l'élément vidéo natif

      // Aller à la 10e seconde et capturer
      video.currentTime = timecapture; // Se déplacer à 10 secondes
      player.video.video.pause();
      video.onseeked = () => {
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // Dessiner l'image
      };
    }
  };

  
  const handleTimeUpdate = () => {
    const { timeStart, timeEnd } = dataTimeClipVideo;
    if (videoRef.current) {
      if (timeEnd==timeStart && timeStart==0) {
        videoRef.current.video.video.pause();
        return false;
      }
      try {
        const currentTime = videoRef.current.getState().player.currentTime;
        setCurrentTime(currentTime);
        if (!isNaN(timeEnd) && timeEnd !== -1 && currentTime >= timeEnd) {
          videoRef.current.video.video.currentTime = timeStart;
        }
        
      } catch (error) {
        console.log('Error updating current time:', error);
      }
    }
  };

  return (
    <div style={{ "position" : "relative" }}>
      <Player
        ref={videoRef}
        fluid={false}
        muted={false} // Mettre la vidéo en sourdine
        height={videoHeight}
        onTimeUpdate={handleTimeUpdate}
        width="100%"
        poster="/assets/poster.png"
        playsInline
        webkit-playsinline="true"
        {...props}>
        <source src={currentSource} />
        <BigPlayButton position="center" className="PlayerBigPlayButton" />
        <ControlBar autoHide={false} className='blockControlBar' disableCompletely={false}>
          <PlayToggle />
          <ProgressControl/>
        </ControlBar>
      </Player>
      <canvas
        ref={canvasRef}
        className="canvasPlayerdisabledClipVideo"
        style={{
          display: disabledClipVideo ? 'block' : 'none',
          height: `${parseInt(videoHeight, 10)}px`,
          border: "3px solid rgb(255, 255, 255)",
        }}
      />
     <div className='mb-[150px]  dumptestclip'>
      <h3 style={{ "color": "#fff" }}>HLSSource</h3>
      <p style={{ "color": "#fff" }}>timeStart : {dataTimeClipVideo.timeStart}</p>
      <p style={{ "color": "#fff" }}>timeEnd : {dataTimeClipVideo.timeEnd}</p>
      <p style={{ "color": "#fff" }}>oldtimeEnd : {oldTimeEnd}</p>
      <p style={{ "color": "#fff" }}>currentTime : {currentTime}</p>
      <p style={{ "color": "#fff" }}>disabledClipVideo: {disabledClipVideo ? "True" : "False"}</p>
      <p style={{ "color": "#fff" }}>currentQualityVideo: {currentQualityVideo}</p>
     </div>

    </div>
  );
};

export default HLSSource;
