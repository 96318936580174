import { Text, Heading, Img, SelectBox } from "components";
import React, { useEffect, useRef, useState } from "react";
import { downloadSequenceClip } from "services/sequencesServices";
import 'react-range-slider-input/dist/style.css';
import { getDureeUserSequences } from "services/userService";
export default function ClipSequence({
  idsequence = "0",
  filmId = "0",
  title = "",
  timesequence= "00:00",
  maxDureeClips=30,
  setDataTimeClipVideo,
  dataTimeClipVideo={timeStart:-1,timeEnd:-1},
  currentQualityVideo,
  setCurrentQualityVideo,
  filmColor="#fff",
  setMaxtimeclip,
  maxtimeclip,
  ...props
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeStart, setTimeStart] = useState('00:00');
  const [timeEnd, setTimeEnd] = useState('00:'+dataTimeClipVideo.timeEnd);
  const [timeStartInput, setTimeStartInput] = useState('00:00');
  const [timeEndInput, setTimeEndInput] = useState('00:'+maxtimeclip);
  const [outputName, setOutputName] = useState(title);
  const [error, setError] = useState('');
  const popupRef = useRef(null);
  const optionsquality=[
    {value: "144p",label: "144p"},
    {value: "240p",label: "240p"},
    {value: "480p",label: "480p"},
    {value: "720p",label: "720p"},
    {value: "1080p",label: "1080p"}
  ];

  useEffect(() => {
    if(dataTimeClipVideo.timeStart!=-1 && dataTimeClipVideo.timeEnd!=-1){
      const calc_timeStart = convertSecondsToTimeString(dataTimeClipVideo.timeStart);
      const calc_timeEnd = convertSecondsToTimeString(dataTimeClipVideo.timeEnd);
      setTimeStart(calc_timeStart);
      setTimeEnd(calc_timeEnd);
      afficherMsgErrorAndUpdateStatusdure();
      setTimeStartInput(calc_timeStart);
      setTimeEndInput(calc_timeEnd);
    }
    setShowPopup(false);
    
  }, [dataTimeClipVideo]);

  useEffect(() => {
    // Ajouter l'écouteur d'événements au montage
    document.addEventListener("mousedown", handleClickOutside);

    // Nettoyer l'écouteur d'événements lors du démontage
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // [] garantit que cet effet ne s'exécute qu'une fois


  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false); // Masquer la popup
    }
  };
  const handleButtonDownloadClick = async (format) => {
    
    setIsLoading(true);
    setShowPopup(false);
    try {
        const response = await downloadSequenceClip(idsequence, padTime(timeStart), padTime(timeEnd), outputName,format,false);
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title}_${outputName}_${format}.mp4`;
        link.click();
        afficherMsgErrorAndUpdateStatusdure(true);
    } catch (error) {
        console.error('Error downloading video clip:', error);
        if (error.response) {
            if (error.response.status === 400 && error.response.data) {
                console.error('Error 400:', error.response.data);
                setError("Un problème est survenu !");
            } else {
                console.error('Server responded with error:', error.response.data);
                setError("Un problème est survenu !");
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
            setError("Un problème est survenu !");
        } else {
            console.error('Error setting up request:', error.message);
            setError("Un problème est survenu !");
        }
    } finally {
        setIsLoading(false);
    }
  };

  const handleButtonClick = async (status) => {
    const controlleclip = await afficherMsgErrorAndUpdateStatusdure(true);
    if( controlleclip ){
      setShowPopup(!showPopup);
    }
   
  };

  const afficherMsgErrorAndUpdateStatusdure = async(force=false) => {
    if(filmId==3){
      return afficherMsgErrorAndUpdateStatusdureV1(force); //pour film Paul Watson
    }else{
      return afficherMsgErrorAndUpdateStatusdureV2(force);
    }
  }

  const afficherMsgErrorAndUpdateStatusdureV1 = async(force=false) => { //pour film Paul Watson
    const { timeStart,timeEnd } = dataTimeClipVideo;
    setError('');
    // Check if timeStart is greater than timeEnd
    if (timeStart === "" || timeEnd === "" || timeStart == -1 || timeEnd == -1 ) {
      setError(" L'heure de début et l'heure de fin doivent être renseignées.");
      console.log("***afficherMsgError : 1");
      
      return false;
    }
    // Check if timeStart is greater than timeEnd
    if (timeStart >= timeEnd) {
      setError("L'heure de début ne peut pas être postérieure à l'heure de fin.");
      console.log("***afficherMsgError : 2");
      return false;
    }

    // Check if outputName is empty
    if (!outputName) {
      setError('Le titre ne peut pas être vide.');
      console.log("***afficherMsgError : 3");
      return false;
    }

    if(force){
      const dataduree = await getDureeUserSequences(idsequence,filmId);

      if(dataduree){

        let new_maxtimeclip = dataduree.maxclipsequence;
        setMaxtimeclip(new_maxtimeclip);
        if(dataduree.maxclip-dataduree.dureeSequences<new_maxtimeclip){
          //setError("Vous avez atteint la limite de téléchargement de "+dataduree.maxclipsequence+" secondes pour cette séquence. Vous pouvez télécharger vos clips dans d'autres formats dans l'espace 'Mes séquences'.");
          new_maxtimeclip = dataduree.maxclip-dataduree.dureeSequences;
          if(new_maxtimeclip<=0){
          	setError("Vous avez atteint la limite de téléchargement de "+dataduree.maxclipsequence+" secondes pour cette séquence. Vous pouvez télécharger vos clips dans d'autres formats dans l'espace 'Mes séquences'.");
          	setMaxtimeclip(0);
          	return false;
          }
          setMaxtimeclip(new_maxtimeclip);
          if((timeEnd-timeStart)>new_maxtimeclip){
            setError('Le clip ne peut pas dépasser '+new_maxtimeclip+' secondes.');
            return false;
          }
          
        }
      }else{
        return false;
      }
    }

    // Check if duration exceeds xx seconds
    if (timeEnd-timeStart > maxtimeclip) {
      if(maxtimeclip==0){
        setError('Vous avez atteint la limite de téléchargement.');
      }else{
        setError('Le clip ne peut pas dépasser '+maxtimeclip+' secondes.');
      }
      console.log("***afficherMsgError : 1");
      return false;
    }
    console.log("***afficherMsgError : end");
    return true;
  };

  const afficherMsgErrorAndUpdateStatusdureV2 = async(force=false) => {
    console.log("***afficherMsgError : start");
    const { timeStart,timeEnd } = dataTimeClipVideo;
    setError('');
    // Check if timeStart is greater than timeEnd
    if (timeStart === "" || timeEnd === "" || timeStart == -1 || timeEnd == -1 ) {
      setError(" L'heure de début et l'heure de fin doivent être renseignées.");
      console.log("***afficherMsgError : 1");
      
      return false;
    }
    // Check if timeStart is greater than timeEnd
    if (timeStart >= timeEnd) {
      setError("L'heure de début ne peut pas être postérieure à l'heure de fin.");
      console.log("***afficherMsgError : 2");
      return false;
    }

    // Check if outputName is empty
    if (!outputName) {
      setError('Le titre ne peut pas être vide.');
      console.log("***afficherMsgError : 3");
      return false;
    }

    if(force){
      const dataduree = await getDureeUserSequences(idsequence,filmId);

      if(dataduree){
        let new_maxtimeclip = dataduree.maxclipsequence-dataduree.dureeSequence;
        setMaxtimeclip(new_maxtimeclip);
        if(dataduree.maxclip-dataduree.dureeSequences<new_maxtimeclip){
          new_maxtimeclip = dataduree.maxclip-dataduree.dureeSequences;
         
          if(new_maxtimeclip<=0){
          	setError("Vous avez atteint la limite de téléchargement de "+dataduree.maxclipsequence+" secondes pour cette séquence. Vous pouvez télécharger vos clips dans d'autres formats dans l'espace 'Mes séquences'.");
          	setMaxtimeclip(0);
          	return false;
          }
        
          setMaxtimeclip(new_maxtimeclip);
          if((timeEnd-timeStart)>new_maxtimeclip){
            setError('Le clip ne peut pas dépasser '+new_maxtimeclip+' secondes.');
            return false;
          }
          
        }
      }else{
        return false;
      }
    }

    // Check if duration exceeds xx seconds
    if (timeEnd-timeStart > maxtimeclip) {
      if(maxtimeclip==0){
        setError('Vous avez atteint la limite de téléchargement.');
      }else{
        setError('Le clip ne peut pas dépasser '+maxtimeclip+' secondes.');
      }
      console.log("***afficherMsgError : 1");
      return false;
    }
    console.log("***afficherMsgError : end");
    return true;
  };

  const padTime = (time) => {
    let [minutes, seconds] = time.split(':');
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;
    return `${minutes}:${seconds}`;
  };

  const handleQualityChange = (selectedOption) => {
    setCurrentQualityVideo(selectedOption.value);  // Store the selected value only
  };

  const handleInputChangeTimeStart = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d:]/g, '');

    if (value.length === 2 && !value.includes(':')) {
        value = value + ':';
    }

    if (value.length > 5) {
        value = value.slice(0, 5);
    }
    setTimeStartInput(value);
    if(isValidTimeFormat(value)){
      console.log(value);
      setTimeStart(value);
      
      setDataTimeClipVideo({timeStart:convertTimeStringToSeconds(value),timeEnd:dataTimeClipVideo.timeEnd});
    }
  };

  const handleInputChangeTimeEnd = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d:]/g, '');

    if (value.length === 2 && !value.includes(':')) {
        value = value + ':';
    }

    if (value.length > 5) {
        value = value.slice(0, 5);
    }
    setTimeEndInput(value);
    if(isValidTimeFormat(value)){
      console.log(value);
      setTimeEnd(value);
      
      setDataTimeClipVideo({timeStart:dataTimeClipVideo.timeStart,timeEnd:convertTimeStringToSeconds(value)});
    }
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  const convertTimeStringToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return (minutes * 60) + seconds;
  };

  const convertSecondsToTimeString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const isValidTimeFormat = (value) => {
    const timeFormatRegex = /^\d{2}:\d{2}$/; // Vérifie deux chiffres, un ":", puis deux chiffres
    return timeFormatRegex.test(value);
  };
  
  // Find the selected option object from optionsquality
  const selectedOption = optionsquality.find(option => option.value === currentQualityVideo);
  

  return (
    <div {...props} className={`${props.className} flex flex-col gap-[13px] h-[100%]`}>
      {isLoading && (
        <div className="loading-recut">
          <Img
            src="/images/loading.gif"
            alt="Loading"
            className="h-[200px] object-cover cursor-pointer"
          />
        </div>
      )}
      <div className="self-stretch container-blockFilmClipsquence items-start justify-center flex flex-col h-[100%]">
        <Heading size="headline_3" as="h3" className="mb-[0px] mt-[30px] text-[24px] sm:text-[16px]">
            Créer un clip
        </Heading>
        <div className="blockFilmClipsquence w-[100%]">
          <div className="input-titreclip shadowhover">
            <input
              placeholder="Nommer votre clip..."
              className="titreclip"
              value={outputName}
              onChange={(e) => setOutputName(e.target.value)}
            />
          </div>
          
          <div className="flex justify-between w-[100%] items-center mt-[50px] sm:mt-[15px]">
                <div>
                  <span className="text-[#fff]">Durée {maxDureeClips>9999?<></>:<span>(Max {maxtimeclip}s )</span>}</span>
                  <div className="newblockFilmClipTime mt-3 shadowhover">
                    <input
                      type="text"
                      placeholder="00:00"
                      className="timeclip w-[50px] text-center"
                      maxLength="5"
                      value={timeStartInput}
                      onChange={handleInputChangeTimeStart}
                    />
                    <span>-</span>
                    <input
                      type="text"
                      placeholder="00:00"
                      className="timeclip w-[50px] text-center"
                      maxLength="5"
                      value={timeEndInput}
                      onChange={handleInputChangeTimeEnd}
                    />
                  </div>
                </div>
                <div>
                  <span className="text-[#fff] mb-1">Qualité</span>
                  <div className="mt-3 p-0">
                    <SelectBox
                      options={optionsquality}
                      value={selectedOption}  // Set value as the full selected option object
                      onChange={handleQualityChange}  
                      isSearchable={true}
                      placeholder="Quality..."
                      shape="round"
                      size="sm"
                      variant="fill"
                      color="gray_900"
                      className="SelectBoxQualityVideo pl-1 pr-1 rounded-[10px] shadowhover"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          zIndex: 0,
                        }),
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          border: "0 !important",
                          boxShadow: "0 !important",
                          minHeight: "auto",
                          width: "100%",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#fff",  // Set input text color to white
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: state.isSelected ? "#fff" : "#000",  // Selected option text in white, others in black
                          backgroundColor: state.isSelected ? "#333" : "transparent", // Optional: Darken background on select
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#fff",  // Set selected option text color to white
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#aaa",  // Optional: Light grey placeholder for better contrast
                          margin: 0,
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: 0,
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                        menu: ({ width, ...css }) => ({ ...css }),
                      }}
                    />
                </div>
              </div>
          </div>
        </div>
        
        <div className="relative w-[100%] mt-[30px]">
          <div className="items-center justify-center flex flex-col w-[100%]">
            <button className="btn-clip" style={{backgroundColor:filmColor,borderRadius:"20px"}} onClick={() => handleButtonClick("download")} disabled={isLoading}>
              <Img
                src="/images/download.svg"
                alt="Download"
                className="h-[30px] sm:h-[30px] object-cover" 
              />
              Télécharger le clip
            </button>
            {error && <div className="error-message">{error}</div>}
          </div>
          {showPopup && (
            <div 
              className="absolute left-[-10px] bottom-[60px] w-[300px] mt-12 ml-12 p-4 bg-white shadowDownloadClick rounded-lg z-50 bg-black-900"
              onMouseLeave={handleMouseLeave}
              ref={popupRef}
            >
              <Text as="h1">Téléchargement</Text>
              <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("vertical")}>
                <Text as="p">Vertical</Text>
                <Img
                  src="/images/icons-social-tiy.svg"
                  alt="image"
                  className="h-[20px] rounded-[7px] object-cover"
                />
                <div>
                  <Img
                    src="/images/download.svg"
                    alt="image"
                    className="h-[20px] w-[20px] rounded-[7px] object-cover"
                  />
                </div>
              </div>
              <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("horizontal")}>
                <Text as="p">Horizontal</Text>
                <Img
                  src="/images/icon-youtube.svg"
                  alt="image"
                  className="h-[20px] rounded-[7px] object-cover"
                />
                <div>
                  <Img
                    src="/images/download.svg"
                    alt="image"
                    className="h-[20px] w-[20px] rounded-[7px] object-cover"
                  />
                </div>
              </div>
              <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("square")}>
                <Text as="p">Carré</Text>
                <Img
                  src="/images/icons-social.svg"
                  alt="image"
                  className="h-[20px] rounded-[7px] object-cover"
                />
                <div>
                  <Img
                    src="/images/download.svg"
                    alt="image"
                    className="h-[20px] w-[20px] rounded-[7px] object-cover"
                  />
                </div>
              </div>
              <div className="flex justify-between items-center btn-telechargement" onClick={() => handleButtonDownloadClick("original")}>
                <Text as="p">Original</Text>
                <div>
                  <Img
                    src="/images/download.svg"
                    alt="image"
                    className="h-[20px] w-[20px] rounded-[7px] object-cover"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
     
    </div>
  );
}